/// regex :
export const hasIdentificationNumberFormat = (identificationNumber: string) => {
  const regex = /^[0-9]{9}$/;
  return regex.test(identificationNumber);
};

export const hasPhoneFormatWithoutAreaCode = (phoneNumber: string) => {
  const regex = /^[0-9]{9}$/;
  return regex.test(phoneNumber);
};

export const hasPhoneFormatWithAreaCode = (phoneNumber: string) => {
  const regex = /^\+\d{2}\d{9}$/;
  return regex.test(phoneNumber);
};

export const hasMobilePhoneFormatWithoutAreaCode = (phoneNumber: string) => {
  const phone = /^[6-7-9]\d{8}$/;
  return phone.test(phoneNumber);
};

export const hasMobilePhoneFormatWithIndex = (phoneNumber: string) => {
  const phone = /^0[6-7-9]\d{8}$/;
  return phone.test(phoneNumber);
};

export const hasMobilePhoneFormatWithAreaCode = (phoneNumber: string) => {
  const regex = /^\+\d{2}[6-7-9]\d{8}$/;
  return regex.test(phoneNumber);
};

export const hasMobilePhoneFormatWithSpaces = (phoneNumber: string) => {
  const regex = /^0[6-7-9](?:\s*\d){8}$/;
  return regex.test(phoneNumber);
};

export const hasMobilePhoneFormat = (phone: string) => {
  return (
    hasMobilePhoneFormatWithoutAreaCode(phone) ||
    hasMobilePhoneFormatWithAreaCode(phone) ||
    hasMobilePhoneFormatWithIndex(phone)
  );
};

export const hasPasswordFormat = (password: string) => {
  const regex = /^(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  return regex.test(password);
};

export const has8Char = (password: string) => {
  return password.length >= 8;
};

export const has1SpecialCharacter = (password: string) => {
  const regex = /[#?!@$%^&*-]/;
  return regex.test(password);
};

export const has1Number = (password: string) => {
  const regex = /[0-9]/;
  return regex.test(password);
};

export const hasEmailFormat = (email: string) => {
  const regex = /^[\w.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

export const hasBirthDateFormat = (birthDate: string) => {
  const regexBirthDate =
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
  return regexBirthDate.test(birthDate);
};

export const hasRegistrationNumberFormat = (registrationNumber: string) => {
  const regexWithoutDashesSIV = /^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$/;
  const regexWithDashesSIV = /^[a-zA-Z]{2}[-][0-9]{3}[-][a-zA-Z]{2}$/;

  const regexWithoutDashesFIN = /^[0-9]{2,4}[a-zA-Z]{3}[0-9]{2,3}$/;
  const regexWithDashesFIN = /^[0-9]{2,4}[-][a-zA-Z]{3}[-][0-9]{2,3}$/;

  return (
    regexWithoutDashesSIV.test(registrationNumber) ||
    regexWithDashesSIV.test(registrationNumber) ||
    regexWithoutDashesFIN.test(registrationNumber) ||
    regexWithDashesFIN.test(registrationNumber)
  );
};

export const hasIdentifierFormat = (identifier: string) => {
  const regexIdentifier = /^QIV_[a-zA-Z0-9]{6}$/;
  return regexIdentifier.test(identifier);
};

export const hasIbanFormat = (iban: string): boolean => {
  return iban.length >= 14 && iban.length <= 34;
};

/// formatter:

export const addPhoneAreaCode = (phone: string) => {
  const phoneFormatted = "+33" + phone;
  return phoneFormatted;
};

export const phoneNumberFormatter = (phone: string) => {
  if (hasMobilePhoneFormatWithAreaCode(phone)) {
    phone = phone.substring(3);
  } else if (hasMobilePhoneFormatWithIndex(phone)) {
    phone = phone.substring(1);
  }
  return phone;
};

export const formatMobilePhoneWithoutSpaces = (phoneNumber: string) => {
  return addPhoneAreaCode(phoneNumber.replaceAll(" ", "").substring(1));
};

export const registrationNumberFormatter = (registrationNumber: string) => {
  const regexSIV = /^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$/;

  const regexFIN2digits3letters2digits = /^[0-9]{2}[a-zA-Z]{3}[0-9]{2}$/;
  const regexFIN3digits3letters2digits = /^[0-9]{3}[a-zA-Z]{3}[0-9]{2}$/;
  const regexFIN4digits3letters2digits = /^[0-9]{4}[a-zA-Z]{3}[0-9]{2}$/;
  const regexFIN2digits3letters3digits = /^[0-9]{2}[a-zA-Z]{3}[0-9]{3}$/;
  const regexFIN3digits3letters3digits = /^[0-9]{3}[a-zA-Z]{3}[0-9]{3}$/;
  const regexFIN4digits3letters3digits = /^[0-9]{4}[a-zA-Z]{3}[0-9]{3}$/;

  const formatRegistrationNumber = (
    part1Length: number,
    part2Length: number,
    part3Length: number,
  ) => {
    return (
      registrationNumber.slice(0, part1Length) +
      "-" +
      registrationNumber.slice(part1Length, part1Length + part2Length) +
      "-" +
      registrationNumber.slice(
        part1Length + part2Length,
        part1Length + part2Length + part3Length,
      )
    );
  };

  if (registrationNumber.match(regexSIV)) {
    registrationNumber = formatRegistrationNumber(2, 3, 2);
  } else if (registrationNumber.match(regexFIN2digits3letters2digits)) {
    registrationNumber = formatRegistrationNumber(2, 3, 2);
  } else if (registrationNumber.match(regexFIN3digits3letters2digits)) {
    registrationNumber = formatRegistrationNumber(3, 3, 2);
  } else if (registrationNumber.match(regexFIN4digits3letters2digits)) {
    registrationNumber = formatRegistrationNumber(4, 3, 2);
  } else if (registrationNumber.match(regexFIN2digits3letters3digits)) {
    registrationNumber = formatRegistrationNumber(2, 3, 3);
  } else if (registrationNumber.match(regexFIN3digits3letters3digits)) {
    registrationNumber = formatRegistrationNumber(3, 3, 3);
  } else if (registrationNumber.match(regexFIN4digits3letters3digits)) {
    registrationNumber = formatRegistrationNumber(4, 3, 3);
  }

  return registrationNumber.toUpperCase();
};

export const transformRegistrationNumber = (input: string) => {
  const matchSIV = RegExp(/^([A-Z]{2})-?(\d{3})-?([A-Z]{2})$/i).exec(input);
  const matchFIN = RegExp(/^([0-9]{2,4})-?([A-Z]{3})-?([0-9]{2,3})$/i).exec(
    input,
  );
  if (matchSIV) {
    return `${matchSIV[1]}-${matchSIV[2]}-${matchSIV[3]}`;
  } else if (matchFIN) {
    return `${matchFIN[1]}-${matchFIN[2]}-${matchFIN[3]}`;
  }
  return input;
};

export const dateFormatterDay = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
  });
  return formatter.format(date);
};

export const dateFormatterLongMonthYear = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    month: "long",
    year: "numeric",
  });
  return formatter.format(date);
};

export const dateFormatterMonthYear = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    month: "short",
    year: "numeric",
  });
  return formatter.format(date);
};

export const dateFormatterDayMonth = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    month: "short",
    day: "2-digit",
  });
  return formatter.format(date);
};

export const dateFormatterDayMonthLong = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    month: "long",
    day: "2-digit",
  });
  return formatter.format(date);
};

export const dateFormatterDayShortMonthYear = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  return formatter.format(date);
};

export const dateFormatterDayMonthYear = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
  return formatter.format(date);
};

export const dateFormatterDayMonthYear2Digits = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
  return formatter.format(date);
};

export const dateFormatterDayMonthYearLong2Digits = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return formatter.format(date);
};

export const dateFormatterSimpleDate = (date: Date) =>
  formatDate(date, {
    year: "numeric",
    day: "2-digit",
    month: "2-digit",
  });

const formatDate = (
  date: Date,
  options: {
    year?: "numeric" | "2-digit";
    month?: "numeric" | "2-digit" | "long" | "short";
    day?: "numeric" | "2-digit";
  },
) => new Intl.DateTimeFormat("fr", options).format(date);

export const dateFormatterHourMinute = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return formatter.format(date);
};
export const dateFormatterDayMonthYearHourMinute = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  return formatter.format(date);
};

export const dateFormatterDayLongMonthYearHourMinuteSecond = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return formatter.format(date);
};

export const dateFormatterDayMonthYearHourMinute2Digits = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
  return formatter.format(date);
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const uppercaseFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const dateFormatterDayMonthYearAndHourMinute2Digits = (date: Date) => {
  const formatter =
    dateFormatterDayMonthYear2Digits(date) +
    " à " +
    dateFormatterHourMinute(date).replaceAll(":", "h");
  return formatter;
};

export const dateFormatterDayMonthLongYearAndHourMinute2Digits = (
  date: Date,
) => {
  const formatter =
    dateFormatterDayMonthYearLong2Digits(date) +
    " à " +
    dateFormatterHourMinute(date).replaceAll(":", "h");
  return formatter;
};

export const formattedZipFolderFileName = (prefix: string, date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const formattedDateTemp = formatter.format(date);
  const formattedDate = formattedDateTemp.split("/").join("_");
  return `${prefix}_${formattedDate}.zip`;
};
