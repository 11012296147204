import {
  SelectDS,
  Spacer,
  TableDS,
  TextCapitalized,
  dateFormatterDayMonthLongYearAndHourMinute2Digits,
  unreachable,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import {
  companiesListAsync,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "../companiesSlice";

import { useParams } from "react-router-dom";
import { RejectedAuthorizationsList } from "./rejectedAuthorizationsApi";
import { rejectedAuthorizationsAsync, selectRejectedAuthorizationsList } from "./rejectedAuthorizationsSlice";

export const RejectedAuthorizationsTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const companiesList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const rejectedAuthorizationList = useAppSelector(selectRejectedAuthorizationsList);

  const [companySelected, setCompanySelected] = useState<string>("");

  const optionCompany = useMemo(
    () =>
      companiesList.map((company) => {
        return {
          label: company.name,
          value: company.uuid,
        };
      }),
    [companiesList],
  );

  const valueOptionCompany = useMemo(
    () => optionCompany.find((option) => option.value === companySelected),
    [companySelected, optionCompany],
  );

  useEffect(() => {
    if (companiesListStatus === "idle") {
      void dispatch(companiesListAsync());
    }
  }, [companiesListStatus, dispatch]);

  useEffect(() => {
    if (companySelected) {
      void dispatch(rejectedAuthorizationsAsync({ companyUuid: companySelected }));
    }
  }, [companySelected, dispatch]);

  const headers = {
    executionDate: {
      text: t("rejectedAuthorizations.column.executionDate"),
    },
    driverName: {
      text: t("rejectedAuthorizations.column.driverName"),
    },
    registrationNumber: {
      text: t("rejectedAuthorizations.column.registrationNumber"),
    },
    merchantName: {
      text: t("rejectedAuthorizations.column.merchantName"),
    },
    amount: {
      text: t("rejectedAuthorizations.column.amount"),
    },
    category: {
      text: t("rejectedAuthorizations.column.category"),
    },
    merchantCity: {
      text: t("rejectedAuthorizations.column.merchantCity"),
    },
    vehicleReference: {
      text: t("rejectedAuthorizations.column.vehicleReference"),
    },
    reasonsRefused: {
      text: t("rejectedAuthorizations.column.reasonsRefused"),
    },
  };

  if (params.tab !== "authorizations") {
    return;
  }

  const render = (row: RejectedAuthorizationsList) => (key: keyof RejectedAuthorizationsList) => {
    switch (key) {
      case "amount":
        return row[key] + " €";
      case "executionDate":
        return dateFormatterDayMonthLongYearAndHourMinute2Digits(
          new Date(row[key]),
        );
      case "category":
        return (
          <TextCapitalized>
            {t(`rejectedAuthorizations.category.${row[key]}`)}
          </TextCapitalized>
        );
      case "reasonsRefused":
      case "registrationNumber":
      case "merchantName":
      case "merchantCity":
      case "driverName":
      case "vehicleReference":
      case "cardLast4Digits":
      case "ruleName":
      case "vehicleUuid":
        return row[key];
    }
    unreachable(key);
  };

  return (
    <StyledContainer>
      <Spacer y={1} />
      <StyledHeader>
        <TextCapitalized>{t("rejectedAuthorizations.select.company")}</TextCapitalized>
        <SelectDS
          label={""}
          value={valueOptionCompany}
          options={optionCompany}
          onChange={(selectedOption) =>
            setCompanySelected(selectedOption ? selectedOption.value : "")
          }
          allWidth
        />
      </StyledHeader>
      <Spacer y={1} />
      <TableDS<keyof RejectedAuthorizationsList, RejectedAuthorizationsList>
        data={rejectedAuthorizationList}
        headers={headers}
        render={render}
      />
      <Spacer y={2} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
`;
